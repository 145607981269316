import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Copyright from "../copyright/copyright";
import GoogleIcon from '@mui/icons-material/Google';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import "./login.css"

const theme = createTheme();

export default function Login() {
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   console.log({
  //     email: data.get("email"),
  //     password: data.get("password"),
  //   });
  // };

  // const [data, setData] = useState({data: []});
  // const [isLoading, setIsLoading] = useState(false);
  // const [err, setErr] = useState('');

  // const handleClick = async () => {
  //   setIsLoading(true)
  //   try {
  //     const {data} = await axios.get('/auth', {
  //       headers: {
  //         Accept: 'application/json',
  //       },
  //     });
  //     console.log('result is: ', JSON.stringify(data, null, 4));

  //     setData(data);
  //   } catch (err) {
  //     setErr(err.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  return (
    <div className="container">
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "#0C317A" }}>
              <WhatshotIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              noValidate
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox 
                      sx={{
                        color: '#8BACAA',
                        ":hover": {
                          color: '#E76161',
      
                        },
                      }}
                      value="allowExtraEmails" color="primary" />
                    }
                    label="I understand GasGoo is super cool"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: '#8BACAA',
                  ":hover": {
                    backgroundColor: '#E76161',

                  },
                  mt: 3, mb: 2,
                }}
              >
                Sign In
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                startIcon={<GoogleIcon />}
                sx={{
                  backgroundColor: '#8BACAA',
                  ":hover": {
                    backgroundColor: '#E76161',

                  },
                  mt: 3, mb: 2
                }}
                href="/auth"
              >
                Sign in
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="/register" variant="body2">
                    I'm new to GasGoo, let me Sign up
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 32 }} />
        </Container>
      </ThemeProvider>
    </div>
  );
}